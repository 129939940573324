import React from 'react';
import ReactDOM from 'react-dom';
import Select from 'react-select';

export const customStyles = {
    control: (provided, state) => ({
        ...provided,
        background: '#fff',
        borderColor: '#9e9e9e',
        minHeight: '50px',
        height: '50px',
        boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
        ...provided,
        height: '30px',
        padding: '0 6px'
    }),

    input: (provided, state) => ({
        ...provided,
        margin: '0px',
    }),
    indicatorSeparator: state => ({
        display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '50px',
    }),
};

export const SmartSelect = ({name, placeholder, options =[], values=[]}) => {
    return (
        <Select name={name} placeholder={placeholder} options={options} defaultValue={values} isMulti/>
    )
};

document.querySelectorAll('.SmartSelectComponent').forEach((item)=>{
    const props = Object.assign({}, item.dataset);
    props.options = JSON.parse(props.options);
    props.values = JSON.parse(props.values);
    ReactDOM.render(<SmartSelect {...props} />, item);
})
